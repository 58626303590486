<template>
  <div class="flex justify-center w-full">
    <div class="w-full sm:w-1/2 px-6 py-2">
      <a class="no-underline" :href="href">
        <SecondaryButton class="w-full py-4">
          <div class="w-1/2">
            <picture class="w-full">
              <source :srcset="srcWebp" type="image/webp">
              <source :srcset="srcJpeg" type="image/jpeg">
              <img :src="srcJpeg" :alt="alt">
            </picture>
          </div>
          <div class="w-1/2 text-left px-4">
            <slot />
          </div>
        </SecondaryButton>
      </a>
    </div>
  </div>
</template>

<script>
  import SecondaryButton from '@/Components/SecondaryButton.vue'

  export default
  {
    components: {
      SecondaryButton
    },
    props: ['srcJpeg', 'srcWebp', 'alt', 'href']
  }
</script>
