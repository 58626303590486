<template>
  <div>
    <div class="text-center">
      <photo-button href="/help" srcJpeg='/images/button_faq.jpg' srcWebp='/images/button_faq.webp' :alt="$t('FAQ')">
        <p class="text-base">{{ $t('stillQuestions') }}</p>
        <p class="text-xl">{{ $t('FAQ') }}</p>
      </photo-button>
      <photo-button v-if="showHomepageLink" href="/" srcJpeg='/images/button_homepage.jpg' srcWebp='/images/button_homepage.webp' :alt="$t('homepage')">
        <p class="text-base">{{ $t('overviewWanted') }}</p>
        <p class="text-xl" v-html="$t('homepage')" />
      </photo-button>
      <photo-button href="/pricing" srcJpeg='/images/button_pricing.jpg' srcWebp='/images/button_pricing.webp' :alt="$t('pricing')">
        <p class="text-base">{{ $t('cost') }}</p>
        <p class="text-xl">{{ $t('pricing') }}</p>
      </photo-button>
    </div>
  </div>
</template>

<script>
import PhotoButton from '@/Shared/PhotoButton.vue'

  export default
  {
    components: {
      PhotoButton
    },
    props: ['showHomepageLink']
  }
</script>
